import { Avatar, Button, Grid } from "@material-ui/core";
import { Alert } from "@mui/material";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Socket, io } from "socket.io-client";
import { getUser } from "../../admin/infra/getUser";
//@ts-ignore
import { ContentPaste, People, Work } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import {
  CanAwaitingType,
  Notifications,
} from "../../admin/hooks/useNotifications";

import { useSocket } from "./SocketProvider";
import { Msg } from "../components/MsgBox";
export interface NotificationData {
  senderName: string;
  type: number;
  onlineUsers: any;
  content?: string;
  codchave?: number;
  user?: string | null;
  userName?: any;
}

export interface OnlineUsersData {
  onlineUsers: any;
}

interface NotificationsContextInterface {
  notifications: NotificationData[];
  onlineUsers: OnlineUsersData | undefined;
  notificationSystem: Notifications[] | undefined;
  awaitingSystem: CanAwaitingType[] | undefined;
  openNot: boolean;
  socket: Socket | null;
  openCanAwaiting: boolean;
  setCanAwaiting: (values: boolean) => void;
  canAwaitingCounter: number;
  setCanAwaitingCounter: (values: number) => void;
  handleNotification: (
    type: number,
    content?: string,
    codchave?: number,
    user?: string | null,
    receiverName?: string | null
  ) => void;
  handleSendCanAwaiting: (
    type: number,
    espera_obs?: string,
    espera_codpessoal?: number,
    espera_usuario?: string | null,
    espera_codvaga?: number,
    userName?: string
  ) => void;
  handleDeleteCanAwaiting: (espera_codigo: number) => void;
  displayNotification: ({ senderName, type }: NotificationData) => void;
}

export const NotificationsContext = createContext(
  {} as NotificationsContextInterface
);

type SnackbarProviderProps = {
  children: React.ReactNode;
};

export type ClientAddress = {
  bairro: String;
  cep: String;
  localidade: String;
  logradouro: String;
  uf: String;
};

const NotificationsProvider = ({ children }: SnackbarProviderProps) => {
  const [socketUuid, setSocketUuid] = useState(uuid());
  const { socket, notifications, setNotifications } = useSocket();

  const [onlineUsers, setOnlineUsers] = useState<OnlineUsersData>();
  const [notificationSystem, setNotificationSystem] =
    useState<Notifications[]>();

  const [awaitingSystem, setAwaitingSystem] = useState<CanAwaitingType[]>();

  const [openNot, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<string | null>();

  const [openCanAwaiting, setCanAwaiting] = useState<boolean>(false);
  const [canAwaitingCounter, setCanAwaitingCounter] = useState<number>(0);

  useEffect(() => {
    socket?.emit("newUser", getUser().id, getUser().userName, socketUuid);
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        socket?.emit("newUser", getUser().id, getUser().userName, socketUuid);
      } else {
        socket?.emit(
          "removeUser",
          getUser().id,
          getUser().userName,
          socketUuid
        );
      }
    });
  }, [socket, user]);

  useEffect(() => {
    if (socket) {
      const handleGetOnlineUsers = (data: OnlineUsersData, off?: boolean) => {
        setOnlineUsers(data);
      };

      const handleGetNotificationsSystem = (data: Notifications[]) => {
        setNotificationSystem(data);
      };

      const handleGetwaitingSystem = (data: CanAwaitingType[]) => {
        setAwaitingSystem(data);
      };

      socket.on("getOnlineUsers", handleGetOnlineUsers);

      socket.on("getNotificationSystem", handleGetNotificationsSystem);

      //Espera Candidatos
      socket.on("getAwaitingSystem", handleGetwaitingSystem);

      return () => {
        socket.off("getOnlineUsers", (data) =>
          handleGetOnlineUsers(data, true)
        );

        socket.off("getNotificationSystem", (data) =>
          handleGetNotificationsSystem(data)
        );

        socket.off("getAwaitingSystem", (data) => handleGetwaitingSystem(data));
      };
    }
  }, [socket]);

  const displayNotification = ({ senderName, type }: NotificationData) => {
    let action;

    if (type === 1) {
      action = "liked";
    } else if (type === 2) {
      action = "commented";
    } else {
      action = "shared";
    }

    return (
      <span className="notification">{`${senderName} ${action} your post.`}</span>
    );
  };

  const handleRead = () => {
    setNotifications([]);
    setOpen(false);
  };

  const handleNotification = (
    type: number,
    content?: string,
    codchave?: number,
    user?: string | null,
    receiverName?: string | null
  ) => {
    if (socket) {
      socket.emit("sendNotification", {
        senderName: getUser().user,
        receiverName: receiverName,
        type,
        content: content,
        codchave: codchave,
        user: user,
      });
    }
  };

  const handleSendCanAwaiting = (
    type: number,
    espera_obs?: string,
    espera_codpessoal?: number,
    espera_usuario?: string | null,
    espera_codvaga?: number,
    userName?: string
  ) => {
    if (socket) {
      socket.emit("sendCandidateToAwaiting", {
        type,
        espera_obs,
        espera_codpessoal,
        espera_usuario,
        espera_codvaga,
        userName,
      });
    }
  };

  const handleDeleteCanAwaiting = (espera_codigo: number) => {
    if (socket) {
      socket.emit("deleteCandidateToAwaiting", {
        espera_codigo,
      });
    }
  };

  useEffect(() => {
    setUser(getUser().id);
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        openNot,
        onlineUsers,
        notificationSystem,
        socket,
        handleNotification,
        displayNotification,
        awaitingSystem,
        handleSendCanAwaiting,
        handleDeleteCanAwaiting,
        openCanAwaiting,
        setCanAwaiting,
        canAwaitingCounter,
        setCanAwaitingCounter,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  return useContext(NotificationsContext);
}

export default NotificationsProvider;
