export function getUser() {
  const permission: any = localStorage.getItem("rh-permissions")
    ? localStorage.getItem("rh-permissions")
    : [];

  let parsedArray;

  try {
    parsedArray = JSON.parse(permission);

    if (!Array.isArray(parsedArray)) {
      parsedArray = [];
    }
  } catch (error) {
    parsedArray = [];
  }

  return {
    id: localStorage.getItem("rh-userId"),
    user: localStorage.getItem("rh-user"),
    userName: localStorage.getItem("rh-userName"),
    email: localStorage.getItem("rh-email"),
    token: localStorage.getItem("rh-token"),
    refreshToken: localStorage.getItem("rh-refreshToken"),
    expireToken: localStorage.getItem("rh-tokenExpire"),
    permissions: parsedArray,
  };
}
