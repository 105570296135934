import { useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  ArrowDownward,
  AutoAwesome,
  KeyboardVoice,
  RestartAlt,
  Send,
} from "@material-ui/icons";
import { Avatar, Chip, Grid, TextField, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { lazy, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Socket, io } from "socket.io-client";
import useSound from "use-sound";
import { User } from "../../users/types/user";
import { Pessoal } from "../hooks/@types/candidateTypes";

//@ts-ignore
import successNot from "../assets/pop.mp3";
//@ts-ignore
import typing from "../assets/loop.mp3";
import { useNotifications } from "../../core/contexts/NotificationsProvider";

const CalendarApp = lazy(() => import("../../calendar/pages/CalendarApp"));

const useStyles = makeStyles({
  visible: {
    "&:hover": {
      color: "#27b279",
    },
  },
});

type UserDialogProps = {
  onAdd?: (user: Partial<User>) => void;
  onClose: () => void;
  onUpdate?: (user: User) => void;
  open: boolean;
  title: string;
  processing: boolean;
  canInfo?: Pessoal;
};

const ModalAgnesAI: React.FC<UserDialogProps> = ({
  onAdd,
  onClose,
  onUpdate,
  open,
  title,
  processing,
  canInfo,
}) => {
  const [play] = useSound(successNot, { volume: 0.05 });
  const [typingSound, { stop }] = useSound(typing, { volume: 0.05 });

  const { socket } = useNotifications();

  const theme = useTheme();
  const d = new Date();
  const [scroll, setScroll] = useState("paper");
  const [height, setHeight] = useState(window.innerHeight);
  const [text, setText] = useState("");
  const [chat, setChat] = useState(
    JSON.parse(localStorage.getItem("chat") || "[]")
  );
  const [loading, setLoading] = useState(false);
  const [isWrite, setIsWrite222] = useState(false);

  const setIsWrite = (value: boolean) => {
    setIsWrite222(value);
  };
  const [animationLoading, setAnimationLoading] = useState(true);
  const [showScrollToButton, setShowScrollToButton] = useState(false);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef(chat);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChatBoxScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = buttonRef.current!;
    if (scrollHeight * 0.9 < scrollTop + clientHeight) {
      setShowScrollToButton(false);
    } else {
      setShowScrollToButton(true);
    }
  };

  useEffect(() => {
    socket?.on("newMessage", async (data: any) => {
      setLoading(false);
      dataRef.current[dataRef.current.length - 1] = {
        ...dataRef.current[dataRef.current.length - 1],
        user: "assistant",
        text:
          data.content === undefined
            ? dataRef.current[dataRef.current.length - 1].text
            : dataRef.current[dataRef.current.length - 1].text + data.content,
      };
      setChat([...dataRef.current]);
      data.content === undefined &&
      dataRef.current[dataRef.current.length - 1] !== ""
        ? setIsWrite(false)
        : setIsWrite(true);
    });

    setAnimationLoading(false);
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [socket]);

  useEffect(() => {
    scrollToBottom();
  }, [animationLoading]);

  useEffect(() => {
    setText(transcript);
  }, [transcript]);

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem("chat", JSON.stringify(chat));
    dataRef.current = [...chat];
  }, [chat]);

  useEffect(() => {
    if (open && canInfo) {
      const stringfied = JSON.stringify(canInfo);

      scrollToBottom();
      setText(stringfied);
      sendInitText("Gere um relátório sobre o candidato.", stringfied);
    } else {
      setChat([]);
    }
  }, [open]);

  const sendText = async (event: React.FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    resetTranscript();
    if (text.trim().length < 5) return;
    setChat((prevChat: any) => [
      ...prevChat,
      {
        id: prevChat.length + 1,
        text: text,
        user: "user",
        time: d.toLocaleTimeString().slice(0, 5),
      },
      {
        id: prevChat.length + 2,
        text: "",
        user: "assistant",
        time: d.toLocaleTimeString().slice(0, 5),
      },
    ]);

    socket?.emit("newMessage", {
      chat: chat,
      text: text,
      date: d.toLocaleDateString() + " " + d.toLocaleTimeString(),
    });

    setLoading(true);
    setText("");
    setIsWrite(true);
  };

  const sendInitText = async (text: string, data: string) => {
    play();
    resetTranscript();
    if (text.trim().length < 5) return;
    setChat((prevChat: any) => [
      ...prevChat,
      {
        id: prevChat.length + 1,
        text: text,
        user: "user",
        time: d.toLocaleTimeString().slice(0, 5),
      },
      {
        id: prevChat.length + 2,
        text: "",
        user: "assistant",
        time: d.toLocaleTimeString().slice(0, 5),
      },
    ]);

    socket?.emit("newMessage", {
      chat: chat,
      text: data,
      date: d.toLocaleDateString() + " " + d.toLocaleTimeString(),
    });

    setLoading(true);
    setText("");
    setIsWrite(true);
  };

  const microphoneFunc = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const onChangeFunc = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setText(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.altKey) {
      e.preventDefault();
      setText(e.currentTarget.value + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      sendText(e as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="user-dialog-title"
      maxWidth="sm"
      scroll="paper"
      className="modalAgnesAI "
      fullScreen
    >
      <DialogContent
        dividers={scroll === "paper"}
        style={{ height: "calc(100vh)" }}
      >
        <Chip
          icon={<AutoAwesome />}
          color="primary"
          label="Gerado com Inteligência Artificial"
        />

        <div
          style={{ height: "calc(-70px + 100vh)" }}
          className={`flex justify-center perspective overflow-hidden`}
        >
          <div
            className={`relative w-full flex flex-col justify-between rounded-3xl 
          ${
            animationLoading ? "my-rotate-y-270" : "my-rotate-y-360"
          } preserve-3d backface-hidden`}
          >
            <div
              id="scroll-id"
              ref={buttonRef}
              onScroll={handleChatBoxScroll}
              className="h-full px-3 py-2 md:px-6 md:py-4 gap-4 overflow-y-auto overflow-x-hidden flex flex-col relative"
            >
              {chat &&
                chat.map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`flex items-end ${
                        item.text === "" && "hidden"
                      } ${
                        item.user === "user" ? "ml-auto" : "mr-auto"
                      } gap-2 text-slate-800`}
                    >
                      {item.user != "user" && (
                        <Avatar
                          sx={{
                            border: `1px solid ${theme.palette.primary.light}`,
                          }}
                          src="https://main.d1xvbis48sm8yx.amplifyapp.com/_next/static/media/ruti.5e914a94.png"
                        ></Avatar>
                      )}
                      <div
                        className={`mr-auto rounded-xl pt-2 pb-1 px-3 lg:max-w-xl max-w-sm md:max-w-md sm:max-w-md
                      ${
                        item.user === "user"
                          ? "text-white bg-slate-800 ml-[36px]"
                          : "text-slate-800 bg-white mr-[36px]"
                      } flex flex-col shadow-md shadow-slate-500`}
                      >
                        <span>
                          <ReactMarkdown
                            // remarkPlugins={[remarkGfm]}
                            className="agnes-ai-markdown break-words"
                          >
                            {item.text && item.text}
                          </ReactMarkdown>
                          {/* {item.text &&
                            item.user != "user" &&
                            item.text
                              .split("\n")
                              .map((line: any, index: any) => {
                                return line;
                              })} */}
                        </span>

                        <span
                          className={`text-[8px] sm:text-[10px] ml-auto -mb-[3px] mt-1 ${
                            item.user === "user"
                              ? "text-white"
                              : "text-slate-800"
                          } opacity-40`}
                        >
                          {item.time}
                        </span>
                      </div>
                    </div>
                  );
                })}
              {loading && (
                <div
                  className={`mr-auto rounded-xl py-2 px-3 lg:max-w-4xl max-w-xs md:max-w-lg text-slate-800 bg-white -mb-3 lg:-ml-1 lg:-mb-6 mt-auto`}
                >
                  <span className="break-words text-xs sm:text-sm flex">
                    <div className="loader-dots block relative w-16 h-3 mt-1">
                      <div className="absolute top-0 w-2 h-2 rounded-full bg-slate-600"></div>
                      <div className="absolute top-0 w-2 h-2 rounded-full bg-slate-600"></div>
                      <div className="absolute top-0 w-2 h-2 rounded-full bg-slate-600"></div>
                      <div className="absolute top-0 w-2 h-2 rounded-full bg-slate-600"></div>
                    </div>
                  </span>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
            <form
              onSubmit={sendText}
              className=" flex justify-between items-center  "
            >
              {/* <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                onKeyDown={handleKeyDown}
                disabled={isWrite}
                onChange={(e) => onChangeFunc(e)}
                value={text}
                placeholder="Haydi sohbet edelim.."
                className="rounded-xl mr-4 md:ml-4 ml-1 overflow-hidden"
              /> */}
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    margin="normal"
                    size="small"
                    autoComplete="off"
                    multiline
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="contato"
                    InputProps={{
                      endAdornment: (
                        <div className="flex gap-2 md:gap-3 md:px-2">
                          <Tooltip disableInteractive title={`Resetar Chat`}>
                            <button
                              type="reset"
                              disabled={isWrite}
                              onClick={() => setChat([])}
                              className={`w-10 h-10 ${
                                !listening && "hover:scale-105"
                              } ${
                                listening && "animate-pulse scale-110"
                              } bg-slate-800 cursor-pointer transition-transform rounded-full flex justify-center items-center`}
                            >
                              <RestartAlt
                                className={`w-5 h-5 text-neutral-200  `}
                              />
                            </button>
                          </Tooltip>
                          <Tooltip disableInteractive title={`Ditar`}>
                            <button
                              type="reset"
                              disabled={isWrite}
                              onClick={() => microphoneFunc()}
                              className={`w-10 h-10 ${
                                !listening && "hover:scale-105"
                              } ${
                                listening && "animate-pulse scale-110"
                              } bg-slate-800 cursor-pointer transition-transform rounded-full flex justify-center items-center`}
                            >
                              <KeyboardVoice
                                className={`w-5 h-5 text-neutral-200  `}
                              />
                            </button>
                          </Tooltip>
                          <Tooltip disableInteractive title={`Enviar`}>
                            <button
                              disabled={isWrite}
                              type="submit"
                              className="w-10 h-10 bg-slate-800 hover:scale-105 cursor-pointer group transition-transform rounded-full flex justify-center items-center"
                            >
                              <Send className="w-4 h-4 text-neutral-200 " />
                            </button>
                          </Tooltip>
                        </div>
                      ),
                    }}
                    placeholder="Escreva aqui..."
                    name="contato"
                    value={text}
                    onChange={(e) => onChangeFunc(e)}
                  />
                </Grid>
              </Grid>
            </form>
            <div
              onClick={() => scrollToBottom()}
              className={`${!showScrollToButton && "hidden"} ${
                !animationLoading && ""
              } absolute bottom-20 right-8 z-50 bg-neutral-200 transition-transform 
            cursor-pointer animate-bounce flex justify-center items-center rounded-full`}
            >
              <ArrowDownward className="text-slate-800 w-12 h-12" />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAgnesAI;
