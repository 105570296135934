import { ClientsType } from "../../admin/hooks/useClients";
import { getFromLocalStorage } from "../../admin/hooks/useUtils";

export default function compareClients(
  id: number | string | undefined,
  clients: ClientsType[]
) {
  if (clients?.length == 0) {
    const array = getFromLocalStorage("clientsSys");
    clients = array;
  }

  if (!clients) {
    return;
  }

  const itemEncontrado = clients.find((item) => item.id == id);

  if (itemEncontrado) {
    return itemEncontrado as any;
  } else {
    return null;
  }
}
