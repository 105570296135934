import Box, { BoxProps } from "@material-ui/core/Box";
import LogoAgnes from "../../admin/assets/logo.png";
type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ colored = false, size = 40, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <img src={LogoAgnes} alt="Logo" style={{ width: "40px" }} />
    </Box>
  );
};

export default Logo;
