import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default function formatDateNull(date: string = "", dateFormat?: string) {
  const noDate = null;
  if (!date) {
    return noDate;
  }

  try {
    // caso não retorne data e hora
    const fixDate = date.search(":") > 0 ? date : `${date} 00:00`;

    const parsedDate = new Date(fixDate);

    const defaultFormat = dateFormat || "d 'de' MMMM 'de' yyyy - HH'h'mm";

    return format(parsedDate, defaultFormat, {
      locale: ptBR,
    });
  } catch (_) {
    return noDate;
  }
}
