import { Box, Button, Tooltip, Typography, useTheme } from "@material-ui/core";
import { Add, CalendarToday, Remove, Sms } from "@material-ui/icons";
import { Divider } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLogin } from "../../auth/hooks/useLogin";
import { useLogout } from "../../auth/hooks/useLogout";
import ModalCalendar from "../components/ModalCalendar";
import ModalSMS from "../components/ModalSMS";
import ModalAgnesAI from "../components/ModalAgnesAI";
import { Pessoal } from "../hooks/@types/candidateTypes";
import { useFunctions } from "../../core/contexts/FunctionsProvider";

interface SmsContextInterface {
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  setCanInfoFunc: (value: Pessoal) => void;
  canInfoContext: Pessoal | undefined;
}

export const SmsContext = createContext({} as SmsContextInterface);

type SmsProviderProps = {
  children?: React.ReactNode;
};

const SmsProvider = ({ children }: SmsProviderProps) => {
  const theme = useTheme();
  //@ts-ignore
  const [zoom, setZoom] = React.useState<string>("100%");
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalOpenCalendar, setModalOpenCalendar] =
    React.useState<boolean>(false);

  const [modalOpenAI, setModalOpenAI] = React.useState<boolean>(false);

  const { isLoggingIn, login } = useLogin();
  const { isLoggingOut, logout } = useLogout();
  //@ts-ignore
  const initialZoomValue = document.body.style.zoom;

  useEffect(() => {
    const getLocalZoom = localStorage.getItem("rh-zoom");
    if (!getLocalZoom) {
      localStorage.setItem("rh-zoom", "100%");
    } else {
      setZoom(getLocalZoom);
    }
    //@ts-ignore
    document.body.style.zoom = getLocalZoom;
  }, [zoom]);

  function hasCandidate(): boolean {
    const url = window.location.href;

    const pattern = /\/candidate\/\d+/;
    return pattern.test(url);
  }

  const zoomIn = () => {
    const getLocalZoom = localStorage.getItem("rh-zoom");
    const sub = getLocalZoom?.slice(0, -1);
    const subtraction = Number(sub) - 1;
    if (Number(sub) >= 81) {
      localStorage.setItem("rh-zoom", `${subtraction}%`);
      //@ts-ignore
      document.body.style.zoom = getLocalZoom;
      setZoom(`${subtraction}%`);
    }
  };

  const zoom100 = () => {
    const getLocalZoom = localStorage.getItem("rh-zoom");
    localStorage.setItem("rh-zoom", `100%`);
    //@ts-ignore
    document.body.style.zoom = getLocalZoom;
    setZoom(`100%`);
  };

  const zoomOut = () => {
    const getLocalZoom = localStorage.getItem("rh-zoom");
    const sub = getLocalZoom?.slice(0, -1);
    const subtraction = Number(sub) + 1;
    if (Number(sub) <= 190) {
      localStorage.setItem("rh-zoom", `${subtraction}%`);
      //@ts-ignore
      document.body.style.zoom = getLocalZoom;
      setZoom(`${subtraction}%`);
    }
  };

  const [canInfoContext, setCanInfoContext] = React.useState<
    Pessoal | undefined
  >();

  function setCanInfoFunc(value: Pessoal) {
    setCanInfoContext(value);
  }

  return (
    <SmsContext.Provider
      value={{
        isLoggingIn,
        isLoggingOut,

        setCanInfoFunc,
        canInfoContext,
      }}
    >
      <ModalSMS
        onClose={() => setModalOpen(!modalOpen)}
        open={modalOpen}
        title="Central de SMS"
        processing={false}
      />
      <ModalCalendar
        onClose={() => setModalOpenCalendar(!modalOpenCalendar)}
        open={modalOpenCalendar}
        title="Agenda"
        processing={false}
      />
      {/*  //@ts-ignore */}
      <ModalAgnesAI
        onClose={() => setModalOpenAI(!modalOpenAI)}
        open={modalOpenAI}
        title="Agnes IA"
        processing={false}
        canInfo={canInfoContext}
      />

      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          display: "flex",
          padding: "8px",
          zIndex: "999",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            color: "text.secondary",
            "& svg": {
              m: 1.5,
            },
            "& hr": {
              mx: 0.5,
            },
          }}
        >
          {/* <Tooltip
            title="Central de SMS"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <Sms
              style={{ cursor: "pointer" }}
              sx={{ fontSize: 25, float: "right" }}
            />
          </Tooltip>
          <Divider orientation="vertical" flexItem /> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              border: `1px solid ${theme.palette.primary.light}`,
              borderRadius: 1,
              borderColor: theme.palette.primary.light,
              bgcolor: "background.paper",
              color: "text.secondary",
              marginRight: 1.5,
              "& svg": {
                m: 1.5,
              },
              "& hr": {
                mx: 0.5,
              },
            }}
          >
            <Tooltip
              title="Agenda"
              placement="right"
              onClick={() => setModalOpenCalendar(!modalOpenCalendar)}
              disableInteractive
            >
              <CalendarToday
                style={{ cursor: "pointer" }}
                sx={{ fontSize: 17, float: "right" }}
              />
            </Tooltip>
          </Box>
          {hasCandidate() && (
            <Box
              onClick={() => setModalOpenAI(!modalOpenAI)}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: 1,
                borderColor: theme.palette.primary.light,
                backgroundSize: "cover !important",
                background:
                  "url(https://main.d1xvbis48sm8yx.amplifyapp.com/_next/static/media/ruti.5e914a94.png) center",
                color: "text.secondary",
                marginRight: 1.5,
                "& svg": {
                  m: 1.5,
                },
                "& hr": {
                  mx: 0.5,
                },
              }}
            >
              <Tooltip title="Agnes AI" placement="right" disableInteractive>
                <CalendarToday
                  style={{ cursor: "pointer", opacity: 0 }}
                  sx={{ fontSize: 17, float: "right" }}
                />
              </Tooltip>
            </Box>
          )}

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              border: `1px solid ${theme.palette.primary.light}`,
              borderRadius: 1,
              borderColor: theme.palette.primary.light,
              bgcolor: "background.paper",
              color: "text.secondary",
              "& svg": {
                m: 1.5,
              },
              "& hr": {
                mx: 0.5,
              },
            }}
          >
            <Tooltip title="" onClick={zoomIn} disableInteractive>
              <Remove
                style={{ cursor: "pointer" }}
                sx={{ fontSize: 17, float: "right" }}
              />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Button size="small" onClick={zoom100}>
              {zoom}
            </Button>

            <Divider orientation="vertical" flexItem />
            <Tooltip title="" onClick={zoomOut} disableInteractive>
              <Add
                style={{ cursor: "pointer" }}
                sx={{ fontSize: 17, float: "right" }}
              />
            </Tooltip>
          </Box> */}
        </Box>
      </div>
      {children}
    </SmsContext.Provider>
  );
};

export function useSms() {
  return useContext(SmsContext);
}

export default SmsProvider;
