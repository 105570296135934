import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { removeOne } from "../../core/utils/crudUtils";
import { Event } from "../types/event";
import api from "../../admin/infra/api";
const URL = process.env.REACT_APP_API_URL + "api/calendar";

/* const deleteEvent = async (eventId: string): Promise<string> => {
  const { data } = await axios.delete("/api/events", { data: eventId });
  return data;
}; */

const deleteEvent = async (eventId: string): Promise<string> => {
  try {
    const response = await api(URL + `/${eventId}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    }); // aguarda a resolução da Promise

    if (response.ok) {
      await response.json(); // aguarda a resolução da Promise de leitura JSON
      return eventId;
    } else {
      // Handle erro de requisição não bem-sucedida, se necessário
      console.error("Erro na requisição:", response.statusText);
      throw new Error("Erro na requisição");
    }
  } catch (error) {
    // Handle outros erros, se necessário
    console.error("Erro ao processar a requisição:", error);
    throw error;
  }
};

export function useDeleteEvent() {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(deleteEvent, {
    onSuccess: (eventId: string) => {
      queryClient.setQueryData<Event[]>(["events"], (oldEvents) =>
        removeOne(oldEvents, eventId)
      );
    },
  });

  return { isDeleting: isLoading, deleteEvent: mutateAsync };
}
