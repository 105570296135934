import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { lazy } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { User } from "../../users/types/user";

const CalendarApp = lazy(() => import("../../calendar/pages/CalendarApp"));

const useStyles = makeStyles({
  visible: {
    "&:hover": {
      color: "#27b279",
    },
  },
});

type UserDialogProps = {
  onAdd?: (user: Partial<User>) => void;
  onClose: () => void;
  onUpdate?: (user: User) => void;
  open: boolean;
  title: string;
  processing: boolean;
};

const ModalCalendar = ({
  onAdd,
  onClose,
  onUpdate,
  open,
  title,
  processing,
}: UserDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="user-dialog-title"
      fullScreen
      maxWidth="xl"
      className="openAnimation"
      sx={{ m: 0, p: 0 }}
    >
      <DialogContent sx={{ m: 0, p: 0, pt: "0 !important" }}>
        <CalendarApp />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose} variant="contained">
          Fechar
        </Button>
        {/*  <LoadingButton loading={processing} type="submit" variant="contained">
            {editMode
              ? t("userManagement.modal.edit.action")
              : t("userManagement.modal.add.action")}
          </LoadingButton> */}
      </DialogActions>
    </Dialog>
  );
};

export default ModalCalendar;
