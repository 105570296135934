import axios from "axios";
import { useQuery } from "react-query";
import { Event } from "../types/event";
import api from "../../admin/infra/api";
const URL = process.env.REACT_APP_API_URL + "api/calendar";

export const fetchEvents = async (): Promise<Event[]> => {
  try {
    const response = await api(URL); // aguarda a resolução da Promise

    if (response.ok) {
      const data = await response.json(); // aguarda a resolução da Promise de leitura JSON
      console.log(data);
      return data;
    } else {
      // Handle erro de requisição não bem-sucedida, se necessário
      console.error("Erro na requisição:", response.statusText);
      throw new Error("Erro na requisição");
    }
  } catch (error) {
    // Handle outros erros, se necessário
    console.error("Erro ao processar a requisição:", error);
    throw error;
  }
};

export function useEvents() {
  return useQuery("events", () => fetchEvents());
}
