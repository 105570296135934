import { Alert, Avatar, Button, Grid } from "@mui/material";
import { NotificationData } from "../contexts/NotificationsProvider";
import { NavLink } from "react-router-dom";
import { ContentPaste, PanTool, People, Work } from "@material-ui/icons";
import useTheme from "@material-ui/core/styles/useTheme";

export const Msg = ({
  user,
  type,
  codchave,
  content,
  senderName,
  onlineUsers,
}: NotificationData) => {
  const theme = useTheme();
  return (
    <div className="msg-container">
      {type === 1 && (
        <Grid container direction="row">
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Grid container direction="row" display="flex">
              <Grid item xs={2}>
                <Avatar sx={{ bgcolor: "orange" }}>
                  {senderName.slice(0, 1).toUpperCase()}
                </Avatar>
              </Grid>
              <Grid item xs={10} sx={{ fontSize: "14px" }}>
                <b>
                  ({user}){senderName}{" "}
                </b>{" "}
                te marcou no <b>F10</b> do cliente!
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Alert icon={false} sx={{ pt: 0, pb: 0, mb: 2 }}>
              {content}
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <NavLink to={`/admin/clients/${codchave}`}>
                <Button
                  startIcon={<ContentPaste />}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Abrir Cliente
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      )}

      {type === 2 && (
        <Grid container direction="row">
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Grid container direction="row" display="flex">
              <Grid item xs={2}>
                <Avatar sx={{ bgcolor: "orange" }}>
                  {senderName.slice(0, 1).toUpperCase()}
                </Avatar>
              </Grid>
              <Grid item xs={10} sx={{ fontSize: "14px" }}>
                <b>
                  ({user}){senderName}{" "}
                </b>{" "}
                te marcou no <b>F10</b> da vaga!
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Alert icon={false} sx={{ pt: 0, pb: 0, mb: 2 }}>
              {content}
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <NavLink to={`/admin/vacancy/${codchave}`}>
                <Button
                  startIcon={<Work />}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Abrir Vaga
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      )}

      {type === 3 && (
        <Grid container direction="row">
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Grid container direction="row" display="flex">
              <Grid item xs={2}>
                <Avatar sx={{ bgcolor: "orange" }}>
                  {senderName.slice(0, 1).toUpperCase()}
                </Avatar>
              </Grid>
              <Grid item xs={10} sx={{ fontSize: "14px" }}>
                <b>
                  ({user}){senderName}{" "}
                </b>{" "}
                te marcou no <b>F10</b> do candidato!
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Alert icon={false} sx={{ pt: 0, pb: 0, mb: 2 }}>
              {content}
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <NavLink to={`/admin/candidate/${codchave}`}>
                <Button
                  startIcon={<People />}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Abrir Candidato
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      )}

      {type === 100 && (
        <Grid container direction="row">
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Grid container direction="row" display="flex">
              <Grid item xs={2}>
                <Avatar sx={{ bgcolor: `${theme.palette.warning.light}` }}>
                  <PanTool
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "20px",
                      ml: -0.5,
                    }}
                  />
                </Avatar>
              </Grid>
              <Grid item xs={10} sx={{ fontSize: "14px" }}>
                <b>{senderName}</b> foi adicionado na <b>lista de espera</b>!
              </Grid>
            </Grid>
          </Grid>
          {content && (
            <Grid item xs={12}>
              <Alert icon={false} sx={{ pt: 0, pb: 0, mb: 2 }}>
                {content}
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};
