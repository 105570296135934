import { Navigate, Route, RouteProps } from "react-router";
import { useAuth } from "../../auth/contexts/AuthProvider";
import { getUser } from "../../admin/infra/getUser";
import FunctionsProvider from "../contexts/FunctionsProvider";
import NotificationsProvider from "../contexts/NotificationsProvider";
import CalendarProvider from "../contexts/CalendarProvider";
import SmsProvider from "../../admin/context/SmsProvider";

type PrivateRouteProps = {
  roles?: string[];
} & RouteProps;

const PrivateRoute = ({
  children,
  roles,
  ...routeProps
}: PrivateRouteProps) => {
  const { hasRole, userInfo, permissionsList } = useAuth();

  const jsonPermissions = getUser().permissions;

  const test = hasRole(routeProps?.path, jsonPermissions);

  if (userInfo) {
    if (!test) {
      return <Navigate to={`/403`} />;
    }
    return (
      <>
        <NotificationsProvider>
          <FunctionsProvider>
            <CalendarProvider>
              <SmsProvider>
                <Route {...routeProps} />
              </SmsProvider>
            </CalendarProvider>
          </FunctionsProvider>
        </NotificationsProvider>
      </>
    );
  } else {
    return <Navigate to={`/login`} />;
  }
};

export default PrivateRoute;
