import { getUser } from "./getUser";
import sessionInterceptor from "./session.interceptor";

export default async function api(url: string, options?: RequestInit) {
  const userToken = getUser().token;
  let headers: RequestInit["headers"] = {};

  if (options) {
    if (options.headers) {
      headers = options.headers;
    }
  }

  Object.assign(headers, { authorization: `Bearer ${userToken}` });

  const response = await fetch(url, {
    ...options,
    headers,
  });

  await sessionInterceptor(response);

  return response;
}
