import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { updateOne } from "../../core/utils/crudUtils";
import { Event } from "../types/event";
import api from "../../admin/infra/api";
const URL = process.env.REACT_APP_API_URL + "api/calendar";

/* const updateEvent = async (event: Event): Promise<Event> => {
  const { data } = await axios.put("/api/events", event);
  return data;
}; */

const updateEvent = async (event: Event): Promise<Event> => {
  try {
    const response = await api(URL, {
      method: "PUT",
      body: JSON.stringify(event),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    }); // aguarda a resolução da Promise

    if (response.ok) {
      const data = await response.json(); // aguarda a resolução da Promise de leitura JSON
      console.log(data);
      return data;
    } else {
      // Handle erro de requisição não bem-sucedida, se necessário
      console.error("Erro na requisição:", response.statusText);
      throw new Error("Erro na requisição");
    }
  } catch (error) {
    // Handle outros erros, se necessário
    console.error("Erro ao processar a requisição:", error);
    throw error;
  }
};

export function useUpdateEvent() {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(updateEvent, {
    onSuccess: (event: Event) => {
      queryClient.setQueryData<Event[]>(["events"], (oldEvents) =>
        updateOne(oldEvents, event)
      );
    },
  });

  return { isUpdating: isLoading, updateEvent: mutateAsync };
}
