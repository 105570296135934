import api from "../../admin/infra/api";
import { useQuery } from "react-query";
import { VacancyType } from "../../users/types/user";
import formatDate from "../../core/utils/formatDate";
import { Pessoal } from "./@types/candidateTypes";

const endpoint = process.env.REACT_APP_API_URL + `api/candidate/avaliable`;
const updateEndpoint = process.env.REACT_APP_API_URL + `api/candidate`;
const selectionOptionsUrl =
  process.env.REACT_APP_API_URL + `api/candidate/selection/options`;
const getByIdUrl = process.env.REACT_APP_API_URL + `api/candidate`;
const getByIdUrlSys = process.env.REACT_APP_API_URL + `api/candidate/system`;
const updateCanInfo =
  process.env.REACT_APP_API_URL + `api/candidate/system/update`;
const deleteCanInfo = process.env.REACT_APP_API_URL + `api/candidate/user`;
const addCanInfo = process.env.REACT_APP_API_URL + `api/candidate/system/add`;
const findAllUrl = process.env.REACT_APP_API_URL + `api/candidate/search`;

async function fetchVacancy(): Promise<undefined | VacancyType[]> {
  try {
    const response = await api(endpoint);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export function useVacancy() {
  return useQuery("vacancy", () => fetchVacancy());
}

export async function updateCandidate(colum: any, value: any, id: number) {
  try {
    const response = await api(updateEndpoint + `/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        colum: colum,
        value: value,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export const deleteCanInfoSys = async (
  id: number | undefined,
  type: string
): Promise<boolean | undefined> => {
  let url = "";
  if (type == "UNIVERSITY") {
    url = deleteCanInfo + `/university/${id}`;
  }
  if (type == "WORKS") {
    url = deleteCanInfo + `/job/${id}`;
  }
  try {
    const response = await api(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.ok) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export async function updateCandidateInfo(
  id: number | undefined,
  body: any,
  type: string
) {
  try {
    const response = await api(updateCanInfo + `/${id}`, {
      method: "POST",
      body: JSON.stringify({
        type: type,
        body: body,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function addCandidateInfo(
  id: number | undefined,
  body: any,
  type: string
) {
  try {
    const response = await api(addCanInfo + `/${id}`, {
      method: "POST",
      body: JSON.stringify({
        type: type,
        body: body,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function execSelectionOptions(id: number | undefined, body: any) {
  try {
    const response = await api(selectionOptionsUrl, {
      method: "POST",
      body: JSON.stringify({
        ...body,
        id: id,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getCandidate(id: number, name: string) {
  try {
    const response = await api(updateEndpoint + `/${id}?name=${name}`);

    const data = await response.text();

    if (name === "pessoal_idade") {
      const getDateBirthday = await api(
        updateEndpoint + `/${id}?name=pessoal_dtnasc`
      );
      const date = await getDateBirthday.text();
      const birthDate = new Date(formatDate(date, "yyyy/MM/dd"));
      const currentDate = new Date();

      const difference = currentDate.getTime() - birthDate.getTime();
      const age = Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    }

    if (data === "") {
      return "";
    }

    if (data === name) {
      return "";
    }

    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getCandidateInfo(id: number | undefined) {
  try {
    const response = await api(updateEndpoint + `/info/${id}`);
    if (response.status >= 203) {
      return undefined;
    }
    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getCanHistory(id: number): Promise<any[] | boolean> {
  try {
    const response = await api(updateEndpoint + `/history/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export type FormacoesNType = {
  id: number;
  nf_descricao: string;
  formacao_n_STRING: string;
};

export const getFormacoesN = async (): Promise<FormacoesNType[] | boolean> => {
  const cepEndpoint =
    process.env.REACT_APP_API_URL + `api/utils/populate?label=formacoes_n`;
  try {
    const response = await api(cepEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data as FormacoesNType[];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export async function getCandidateById(id: number): Promise<any> {
  try {
    const response = await api(getByIdUrl + `?id=${id}`);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getCandidateByIdSys(
  id: number
): Promise<Pessoal | undefined> {
  try {
    const response = await api(getByIdUrlSys + `/${id}`);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function findAllCandidates(filterValues?: any): Promise<any> {
  let response;
  try {
    if (filterValues) {
      response = await api(findAllUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filterValues),
      });
    } else {
      response = await api(findAllUrl);
    }

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data.body;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
