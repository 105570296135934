import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";
import "./admin/pages/css/general.css";
import AuthProvider from "./auth/contexts/AuthProvider";
import Loader from "./core/components/Loader";
import QueryWrapper from "./core/components/QueryWrapper";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";
import usePageTracking from "./core/hooks/usePageTracking";
import { ToastContainer } from "react-toastify";
import SocketProvider from "./core/contexts/SocketProvider";
require("dotenv").config();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      suspense: true,
    },
  },
});

function App() {
  usePageTracking();
  useEffect(() => {
    console.log("CONECTO APPPPP");
  }, []);
  return (
    <React.Suspense fallback={<Loader />}>
      <SocketProvider>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <QueryWrapper>
              <SnackbarProvider>
                <AuthProvider>
                  <AppRoutes />
                  <ToastContainer />
                </AuthProvider>
              </SnackbarProvider>
            </QueryWrapper>
          </SettingsProvider>
          {/* <ReactQueryDevtools initialIsOpen /> */}
        </QueryClientProvider>
      </SocketProvider>
    </React.Suspense>
  );
}

export default App;
