import React, { createContext, useContext, useState } from "react";
import { useLocalStorage } from "../../core/hooks/useLocalStorage";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";
import { getUser } from "../../admin/infra/getUser";

interface AuthContextInterface {
  hasRole: (path: any, jsonPermissions?: any) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  permissionsList: any;
  permissions: () => Promise<any>;
  userInfo?: UserInfo;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  console.log("AuthProvider");
  const [authKey, setAuthKey] = useLocalStorage<string>("authkey", "");
  const [permissionsData, setPermissions] = useState<any>([]);

  const { isLoggingIn, login, permissions } = useLogin();
  const { isLoggingOut, logout } = useLogout();

  const { data: userInfo } = useUserInfo(authKey);

  const hasRole = (path?: string, jsonPermissions?: any) => {
    const permission = jsonPermissions.find((item: any) =>
      item.path?.endsWith(path)
    );

    if (permission) {
      return permission.allow;
    } else {
      return true;
    }
  };

  const handleLogin = async (email: string, password: string) => {
    return login({ email, password })
      .then((key: string) => {
        setAuthKey(key);
        return key;
      })
      .catch((err) => {
        console.log("Erro");
        throw err;
      });
  };

  const handleLogout = async () => {
    return logout()
      .then((data) => {
        setAuthKey("");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const handlePermission = async () => {
    return permissions()
      .then((data) => {
        setPermissions(data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  function findObjectByPath(data?: any, path?: string) {
    return data?.find((item: any) => item.path.includes(path));
  }

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        permissionsList: permissionsData,
        permissions: handlePermission,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
