import { ClientContactsType } from "../../admin/hooks/useClients";
import { getFromLocalStorage } from "../../admin/hooks/useUtils";

export default function compareContactsAg(
  id: number | string | undefined,
  contacts: readonly ClientContactsType[]
) {
  if (!contacts) {
    return;
  }

  const itemEncontrado = contacts.find((item) => item.codigo == id);

  if (itemEncontrado) {
    return itemEncontrado as any;
  } else {
    return null;
  }
}
