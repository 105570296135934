import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import {
  Card,
  CardContent,
  CardHeader,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add,
  AlternateEmail,
  AttachFile,
  Cake,
  Call,
  EmojiEmotions,
  LocationOn,
  Mic,
  MoreHoriz,
  Search,
  Send,
  TextFormat,
  Work,
} from "@material-ui/icons";
import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { User } from "../../users/types/user";
interface State {
  amount: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}

const useStyles = makeStyles({
  visible: {
    "&:hover": {
      color: "#27b279",
    },
  },
});

type UserDialogProps = {
  onAdd?: (user: Partial<User>) => void;
  onClose: () => void;
  onUpdate?: (user: User) => void;
  open: boolean;
  title: string;
  processing: boolean;
};

const ModalSMS = ({
  onAdd,
  onClose,
  onUpdate,
  open,
  title,
  processing,
}: UserDialogProps) => {
  const classes = useStyles();

  const [values, setValues] = React.useState<State>({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="user-dialog-title"
      fullWidth
      maxWidth="xl"
    >
      <form style={{ backgroundColor: "#edeff1" }} noValidate>
        <DialogTitle id="user-dialog-title" style={{ marginBottom: "-15px" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6} md={3}>
              <Grid item xs={12} md={12} style={{ marginBottom: "16px" }}>
                <Card>
                  <CardHeader
                    title="Conversas"
                    style={{ marginTop: "-16px", marginBottom: "10px" }}
                    action={
                      <React.Fragment>
                        <Tooltip title="Adicionar" disableInteractive>
                          <IconButton aria-label="Adicionar">
                            <Add />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Buscar"
                          color="error"
                          disableInteractive
                        >
                          <IconButton aria-label="Remover">
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    }
                  />
                </Card>
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card style={{ height: "calc(100vh - 236px)" }}>
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                        marginBottom={2}
                      >
                        <Grid item xs marginTop={2}>
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              alt="Remy Sharp"
                              src="/static/images/avatar/1.jpg"
                              variant="rounded"
                              sx={{ width: 71, height: 71 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={9} marginTop={2}>
                          <CardHeader
                            title="Juliano Oliveira"
                            style={{
                              marginTop: "-25px",
                              marginBottom: "10px",
                              marginLeft: "-23px",
                            }}
                          />
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "-10px" }}
                          >
                            Lorem Ipsum is simply dummy text of the printing
                            and...
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />

                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs marginTop={2}>
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              alt="Remy Sharp"
                              src="/static/images/avatar/1.jpg"
                              variant="rounded"
                              sx={{ width: 71, height: 71 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={9} marginTop={2}>
                          <CardHeader
                            title="Juliano Oliveira"
                            style={{
                              marginTop: "-25px",
                              marginBottom: "10px",
                              marginLeft: "-23px",
                            }}
                          />
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "-10px" }}
                          >
                            Lorem Ipsum is simply dummy text of the printing
                            and...
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={7}>
              <Grid item xs={12} md={12} style={{ marginBottom: "16px" }}>
                <Card style={{ height: "calc(100vh - 236px)" }}>
                  <CardHeader
                    title="Juliano Oliveira"
                    style={{ marginTop: "-16px", marginBottom: "10px" }}
                    action={
                      <React.Fragment>
                        <Tooltip title="Opções">
                          <IconButton aria-label="Opções">
                            <MoreHoriz />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    }
                  />
                  <Divider />
                  <CardContent style={{ minHeight: "350px" }}>
                    mensagenss
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <FilledInput
                  fullWidth
                  id="filled-adornment-password"
                  type="text"
                  value={values.password}
                  onChange={handleChange("password")}
                  multiline
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginTop: "-15px" }}
                      >
                        <TextFormat />
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginTop: "-15px" }}
                      >
                        <EmojiEmotions />
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginTop: "-15px" }}
                      >
                        <AttachFile />
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginTop: "-15px" }}
                      >
                        <Mic />
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginTop: "-15px" }}
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={2}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12}>
                  <Card>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ marginTop: "19px", marginLeft: "55px" }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                          sx={{ width: 135, height: 135 }}
                          variant="rounded"
                        />
                      </Stack>
                    </Grid>

                    <CardContent style={{ minHeight: "400px" }}>
                      <Grid item xs={12} md={12} style={{ marginLeft: "11px" }}>
                        <CardHeader
                          title="Juliano Oliveira"
                          style={{ marginTop: "-16px", marginBottom: "10px" }}
                        />
                      </Grid>
                      <Divider />
                      <Grid item xs marginTop={2}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <LocationOn />
                        </IconButton>
                      </Grid>
                      <Grid item xs={9} marginTop={2}>
                        <Typography>
                          Tv. Alexandre F. Sobrinho - Cidade JardimSão José dos
                          Pinhais - PR, 83035-260
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item xs={12} md={1}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <Cake />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography>27 Anos</Typography>
                      </Grid>
                      <Divider />
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <AlternateEmail />
                        <Typography>juliano_oliveira@gmail.com</Typography>
                      </IconButton>
                      <Divider />
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <Work />
                        <Typography>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </Typography>
                      </IconButton>
                      <Divider />
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <Call />
                        <Typography>(41)9 97138298 </Typography>
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Fechar
          </Button>
          {/*  <LoadingButton loading={processing} type="submit" variant="contained">
            {editMode
              ? t("userManagement.modal.edit.action")
              : t("userManagement.modal.add.action")}
          </LoadingButton> */}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalSMS;
