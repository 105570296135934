import { format } from "date-fns";
import api from "../../admin/infra/api";
import { useFunctions } from "../../core/contexts/FunctionsProvider";

const findAllUrl = process.env.REACT_APP_API_URL + `api/clients/search`;
const clientlUrl = process.env.REACT_APP_API_URL + `api/clients`;
const clientContactsUrl =
  process.env.REACT_APP_API_URL + `api/phone/client/contacts`;

export type ClientHistory = {
  data: string;
  nome_do_cargo: string;
  codigo: number;
  selecionados: number;
  encaminhados: number;
  contratados: number;
};

export type ClientContractsType = {
  datem?: string | null;
  datare: string | null;
  contrato: string;
  observ: string;
  id?: number;
  codigo?: number | null;
  cliente: number;
  dataini: string | null;
  datafin: string | null;
  codsituacao: number;
  dtpgtofunc: any;
  dtadtofunc: any;
  tipopgto: any;
  tipoadto: any;
  diasfolha: any;
  diasfatura: any;
  pzentrfat: any;
  pzvencfat: any;
  fatadto: any;
  tipoentfat: any;
  descricao: string;
  arquivo: any;
  senha: any;
  sigepag: number;
  tipo: string | null;
  contas_id: number;
};

export type ClientProposalType = {
  id?: number;
  codigo?: number;
  data?: string;
  nproposta: string;
  contato: number | null;
  observ: any | null;
  cliente: number | null;
  codretorno: any | null;
  recsel: number | null;
  tempor: number | null;
  terceir: number | null;
  admfolha: number | null;
  outros: any | null;
  txrecsel: string;
  txtempor: any | null;
  txterceir: any | null;
  txadmfolha: any | null;
  txoutros: any | null;
  arquivo: any | null;
  dataret: string | null | undefined;
  contas_id: number | null;
  data_proposta: string | null | undefined;
};

export type ClientContactsType = {
  codigo: number;
  contato: string;
  aniver: string;
  funcao: string;
  observ: string;
  cliente: number;
  timefutebol: any;
  alcool: number;
  fuma: number;
  religiao: string;
  email: string;
  fonepessoal: string;
  emailpessoal: string;
  dtadmissao: string;
  dtdemissao: string;
  ativo: any;
  fonecorporativo: any;
  linkedIn: string;
  contas_id: number;
  id?: number | null;
};

export type ClientsType = {
  id: number;
  fantasia: string;
  atualizada: string;
  nome: string;
  consultor: string;
  cep: string;
  cidade: string;
  bairro: string;
  endereco: string;
  numfunc: any;
  numero_end: number;
  uf: string;
  classificacao: string;
  mestemp: any;
  ativovizu: number;
  vendedor: string;
};

export async function findAllClients(): Promise<any> {
  try {
    const response = await api(findAllUrl);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getClient(id: number, name: string) {
  try {
    const response = await api(clientlUrl + `/${id}?name=${name}`);

    const data = await response.text();
    if (data === "") {
      return "";
    }

    if (data === name) {
      return "";
    }

    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getClientInfo(id: number): Promise<any | boolean> {
  try {
    const response = await api(clientlUrl + `/info/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}
export async function getClientHistory(
  id: number
): Promise<ClientHistory[] | boolean> {
  try {
    const response = await api(clientlUrl + `/history/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getClientContacts(
  id: number
): Promise<ClientContactsType[] | boolean> {
  try {
    const response = await api(clientContactsUrl + `/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getClientProposal(
  id: number
): Promise<ClientProposalType[] | boolean> {
  try {
    const response = await api(clientlUrl + `/proposal/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getClientContracts(
  id: number
): Promise<ClientContractsType[] | boolean> {
  try {
    const response = await api(clientlUrl + `/contract/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function updateClient(
  colum: any,
  value: any,
  id: number
): Promise<any> {
  try {
    const response = await api(clientlUrl + `/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        colum: colum,
        value: value,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function insertClient(
  body: any
): Promise<{ id: number } | undefined> {
  try {
    const response = await api(clientlUrl, {
      method: "POST",
      body: JSON.stringify({ ...body }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
