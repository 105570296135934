import React, {
  FormEvent,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  getVacancyInfo,
  getVacancyInfoAll,
  saveSelectionPut,
} from "../../admin/hooks/useVacancy";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import { Save } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";

interface SelectionContextInterface {
  selectionFormData: any;
  setSelectionData: (value: any) => void;
  vacancyInfo: any;
  vacancyInfoAll: any;
  setVacancyInfo: (value: any) => void;
  loadingInfo: boolean;
  setVacancyInfoAll: (value: any) => void;
  loadingInfoAll: boolean;
  selectionCEP: string;
  setSelectionCep: (value: string) => void;
  setOnEditSelection: (value: boolean) => void;
}

export const SelectionContext = createContext({} as SelectionContextInterface);

type SnackbarProviderProps = {
  children: React.ReactNode;
};

interface FormData {
  [key: string]: any;
}

const SelectionProvider = ({ children }: SnackbarProviderProps) => {
  const { id } = useParams();
  const vagCOD = Number(id);

  const [selectionFormData, setSelectionData] = useState<any | undefined>();
  const [vacancyInfo, setVacancyInfo] = useState<any | undefined>({});
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
  const [onEditSelection, setOnEditSelection] = useState<boolean>(false);
  const [loadingSaveSelection, setLoadingSaveSelection] =
    useState<boolean>(false);
  const [selectionCEP, setSelectionCep] = useState<string>("");

  const [vacancyInfoAll, setVacancyInfoAll] = useState<any | undefined>({});
  const [loadingInfoAll, setLoadingInfoAll] = useState<boolean>(true);

  const getInfo = async (id: number): Promise<any | boolean> => {
    setLoadingInfo(true);
    const data = await getVacancyInfo(id);
    if (data as any) {
      setVacancyInfo(data as any[]);
      setLoadingInfo(false);
      return data;
    }
    setLoadingInfo(false);
    return false;
  };

  const getInfoAll = async (id: number): Promise<any | boolean> => {
    setLoadingInfo(true);
    const data = await getVacancyInfoAll(id);
    if (data as any) {
      setVacancyInfoAll(data as any[]);
      setLoadingInfoAll(false);
      return data;
    }
    setLoadingInfo(false);
    return false;
  };

  const saveSelection = async () => {
    setLoadingSaveSelection(true);
    await saveSelectionPut(vagCOD, selectionFormData);
    setLoadingSaveSelection(false);
  };

  useEffect(() => {
    getInfoAll(vagCOD);
    getInfo(vagCOD);
  }, []);

  return (
    <SelectionContext.Provider
      value={{
        selectionFormData,
        setSelectionData,
        vacancyInfo,
        vacancyInfoAll,
        setVacancyInfo,
        setVacancyInfoAll,
        loadingInfo,
        loadingInfoAll,
        selectionCEP,
        setSelectionCep,
        setOnEditSelection,
      }}
    >
      {children}
      {onEditSelection && (
        <div className="selectionButton fadeInDown">
          <LoadingButton
            loading={loadingSaveSelection}
            startIcon={<Save />}
            size="small"
            variant="contained"
            fullWidth
            onClick={() => saveSelection()}
          >
            Salvar Seleção
          </LoadingButton>
        </div>
      )}
    </SelectionContext.Provider>
  );
};

export function useSelection() {
  return useContext(SelectionContext);
}

export default SelectionProvider;
