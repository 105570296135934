import { useMutation } from "react-query";
import api from "../../admin/infra/api";
import axios from "axios";

const sessionAdmin = process.env.REACT_APP_API_URL + `api/session/admin`;

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<any> => {
  try {
    const response = await api(sessionAdmin, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        pwd: password,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    const data = await response.json();

    localStorage.setItem("rh-user", data.nome);
    localStorage.setItem("rh-userName", data.nomecomp);
    localStorage.setItem("rh-userId", data.codigo);
    localStorage.setItem("rh-email", data.email);
    localStorage.setItem("rh-key", data.key);
    localStorage.setItem("rh-token", data.token);
    localStorage.setItem("rh-refreshToken", data.refresh_token);
    localStorage.setItem("rh-tokenExpire", data.expire);

    if (response.status >= 203) {
      return false;
    }
    console.log(data.key);
    return data.key;
  } catch (err) {
    console.log(err);
    return false;
  }
  /* const { data } = await axios.post(`/api/login`, { email, password });

  console.log(data);
  return; */
};

const permissions = async (): Promise<any> => {
  try {
    const response = await api(sessionAdmin + `/permissions`);

    const data = await response.json();

    if (response.status >= 203) {
      return false;
    }

    localStorage.setItem("rh-permissions", JSON.stringify(data.permissions));
    return data.permissions;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return {
    isLoggingIn: isLoading,
    login: mutateAsync,
    permissions: permissions,
  };
}
