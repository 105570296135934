import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentUrl } from "../utils/getCurrentUrl";
import { fetchEvents, useEvents } from "../../calendar/hooks/useEvents";
import { Event } from "../../calendar/types/event";
import EventDialog from "../../calendar/components/EventDialog";
import { useAddEvent } from "../../calendar/hooks/useAddEvent";
import { useSnackbar } from "./SnackbarProvider";
import { getUser } from "../../admin/infra/getUser";
import { useFunctions } from "./FunctionsProvider";
import { useUpdateEvent } from "../../calendar/hooks/useUpdateEvent";
import { useTranslation } from "react-i18next";
import { useDeleteEvent } from "../../calendar/hooks/useDeleteEvent";

interface SelectionContextInterface {
  handleOpenEventDialog: (event?: Event) => void;
  setHisId: Dispatch<SetStateAction<number>>;
  setCanHisId: Dispatch<SetStateAction<number>>;
  codChave: number;
  hasPattern: (type: "vacancy" | "candidate" | "clients") => boolean;
}

export const CalendarContext = createContext({} as SelectionContextInterface);

type CalendarProviderProps = {
  children: React.ReactNode;
};

const CalendarProvider = ({ children }: CalendarProviderProps) => {
  const { id } = useParams();
  const idParam = Number(id);
  const { t } = useTranslation();

  const [hisId, setHisId] = useState<number>(0);
  const [hisCanId, setCanHisId] = useState<number>(0);

  const [codChave, setCodChave] = useState<number>(0);
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const { data } = useEvents();
  const { addEvent, isAdding } = useAddEvent();
  const { isUpdating, updateEvent } = useUpdateEvent();
  const { deleteEvent, isDeleting } = useDeleteEvent();

  const processing = isAdding || isDeleting || isUpdating;

  const snackbar = useSnackbar();
  const url = getCurrentUrl();

  const { handleF10 } = useFunctions();

  const [dataEvents, setDataEvents] = useState<Event[] | undefined>(data);

  const [eventUpdated, setEventUpdated] = useState<Event | undefined>(
    undefined
  );
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [eventDeleted, setEventDeleted] = useState<string | undefined>(
    undefined
  );

  function hasPattern(type: "vacancy" | "candidate" | "clients"): boolean {
    if (Number(idParam)) {
      const patterns = {
        vacancy: /\/vacancy\/\d+/,
        candidate: /\/candidate\/\d+/,
        clients: /\/clients\/\d+/,
      };

      return patterns[type].test(url);
    }
    return false;
  }

  const getEventData = async () => {
    await fetchEvents().then((res) => {
      setDataEvents(res);
    });
  };

  const handleCloseEventDialog = () => {
    getEventData();
    setEventUpdated(undefined);
    setOpenEventDialog(false);
  };

  const handleOpenEventDialog = (event?: Event) => {
    setEventUpdated(event);
    setOpenEventDialog(true);
  };

  const handleAddEvent = async (event: Partial<Event>) => {
    addEvent(event as Event)
      .then(() => {
        snackbar.success(
          t("calendar.notifications.addSuccess", { event: event.title })
        );
        getEventData();
        setOpenEventDialog(false);
        if (url.includes("admin/client")) {
          const match = url.match(/\/clients\/(\d+)/);
          const clientNumber = match ? Number(match[1]) || 0 : 0;

          handleF10(
            clientNumber,
            "comentario",
            "cliente",
            `**AGENDA** - Agenda ${event.title} Adicionada - 
            ${event.description} - *** ${getUser().userName}`
          );
        }
      })
      .catch(() => {
        snackbar.error(t("common.errors.unexpected.subTitle"));
      });
  };

  const handleOpenConfirmDeleteDialog = (eventId: string) => {
    setEventDeleted(String(eventId));
    setOpenConfirmDeleteDialog(true);
  };

  const handleUpdateEvent = async (event: Event) => {
    updateEvent(event)
      .then(() => {
        snackbar.success(
          t("calendar.notifications.updateSuccess", { event: event.title })
        );

        getEventData();
        setOpenEventDialog(false);
      })
      .catch(() => {
        snackbar.error(t("common.errors.unexpected.subTitle"));
      });
  };

  useEffect(() => {
    if (Number(idParam)) {
      setCodChave(idParam);
      console.log(idParam);
    } else {
      setCodChave(0);
    }
  }, [idParam]);

  return (
    <CalendarContext.Provider
      value={{
        codChave,
        hasPattern,
        handleOpenEventDialog,
        setHisId,
        setCanHisId,
      }}
    >
      <>
        {children}
        {openEventDialog && (
          <EventDialog
            onAdd={handleAddEvent}
            onClose={handleCloseEventDialog}
            onDelete={handleOpenConfirmDeleteDialog}
            onUpdate={handleUpdateEvent}
            open={openEventDialog}
            processing={processing}
            event={eventUpdated}
            hisCanId={hisCanId}
            hisId={hisId}
          />
        )}
      </>
    </CalendarContext.Provider>
  );
};

export function useCalendar() {
  return useContext(CalendarContext);
}

export default CalendarProvider;
