import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { toast } from "react-toastify";
import { Socket, io } from "socket.io-client";
import useSound from "use-sound";
import { Msg } from "../components/MsgBox";
import { NotificationData } from "./NotificationsProvider";

//@ts-ignore
import socketNot from "./LevelUp.mp3";
//@ts-ignore
import canNot from "./candidateAwaiting.mp3";

interface SocketContextInterface {
  socket: Socket | null;
  notifications: NotificationData[];
  setNotifications: (prev: NotificationData[] | any) => void;
}

export const SocketContext = createContext({} as SocketContextInterface);

type SocketProviderProps = {
  children: React.ReactNode;
};

const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const [play] = useSound(socketNot, { volume: 0.4 });
  const [play2] = useSound(canNot, { volume: 0.4 });

  const divRef = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);

  const clickDivAutomatically = (type: number) => {
    if (type >= 100) {
      if (div2Ref.current) {
        div2Ref.current.click();
      }
    } else {
      if (divRef.current) {
        divRef.current.click();
      }
    }
  };

  useEffect(() => {
    setSocket(
      io(`${process.env.REACT_APP_SOCKET_URL}`, {
        reconnection: true, // Ativar reconexão automática
        reconnectionAttempts: Infinity, // Tentativas de reconexão infinitas
        reconnectionDelay: 1000, // Intervalo entre tentativas de reconexão (em milissegundos)
      })
    );
  }, []);

  useEffect(() => {
    if (socket) {
      const handleGetNotification = (data: NotificationData, off?: boolean) => {
        setNotifications((prev) => [...prev, data]);

        clickDivAutomatically(data.type);

        toast(
          <Msg
            senderName={data.senderName}
            type={data.type}
            user={data.user}
            onlineUsers={data.onlineUsers}
            codchave={data.codchave}
            content={data.content}
          />,
          {
            progress: 0,
            hideProgressBar: true,
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      };

      socket.on("getNotification", handleGetNotification);

      return () => {
        socket.off("getNotification", (data) =>
          handleGetNotification(data, true)
        );
      };
    }
  }, [socket]);

  const playSound = () => {
    play();
  };
  const playSound2 = () => {
    play2();
  };
  return (
    <SocketContext.Provider
      value={{
        socket,
        notifications,
        setNotifications,
      }}
    >
      {children}
      <div
        ref={divRef}
        onClick={() => playSound()}
        style={{ display: "none" }}
      ></div>
      <div
        ref={div2Ref}
        onClick={() => playSound2()}
        style={{ display: "none" }}
      ></div>
    </SocketContext.Provider>
  );
};

export function useSocket() {
  return useContext(SocketContext);
}

export default SocketProvider;
