export default async function sessionInterceptor(response: Response) {
  if (response.status === 401) {
    console.warn("Sessão expirada");
    //console.log(response.status);

    window.location.replace("/login");
    // destruir sessão do usuario
    return;
  }
}
