import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";

import { Text } from "recharts";
import Login from "./auth/pages/Login";
import SelectionProvider from "./core/contexts/SelectionProvider";
// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const HelpCenter = lazy(() => import("./admin/pages/HelpCenter"));
const Home = lazy(() => import("./admin/pages/Home"));
const Redirect = lazy(() => import("./admin/pages/Redirect"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));

//Board
const Board = lazy(() => import("./admin/pages/Board"));

//Res
const Res = lazy(() => import("./admin/pages/Res"));

//Vacancy
const Vacancy = lazy(() => import("./admin/pages/Vacancy"));
const VacancyPage = lazy(() => import("./admin/pages/VacancyPage"));

//Emails
const Emails = lazy(() => import("./admin/pages/Emails"));
const EmailsPage = lazy(() => import("./admin/pages/EmailsPage"));

//Announce
const Announce: any = lazy(() => import("./admin/pages/AnnouncePage"));
const AnnounceTable = lazy(() => import("./admin/pages/Announce"));

//Announce
const Clients = lazy(() => import("./admin/pages/ClientPage"));
const ClientsTable = lazy(() => import("./admin/pages/Client"));

//Candidate
//const Candidate = lazy(() => import("./admin/pages/Candidate"));
const CandidatePage = lazy(() => import("./admin/pages/CandidatePage"));
const CandidateTable = lazy(() => import("./admin/pages/Candidate"));

//Settings
const Settings = lazy(() => import("./admin/pages/Settings"));

// Auth
const ForgotPassword = lazy(() => import("./auth/pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
  () => import("./auth/pages/ForgotPasswordSubmit")
);
//const Login = lazy(() => import("./auth/pages/Login"));
const Register = lazy(() => import("./auth/pages/Register"));

// Calendar
const CalendarApp = lazy(() => import("./calendar/pages/CalendarApp"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);

// Landing
const Landing = lazy(() => import("./landing/pages/Landing"));

// Users
const UserManagement = lazy(() => import("./users/pages/UserManagement"));

const AppRoutes = () => {
  return <Login />;
};

const AppRoutesOld = () => {
  return (
    <Routes basename="/">
      <Route path="/" element={<Login />} />
      <PrivateRoute path="admin" element={<Admin />}>
        <PrivateRoute path="/" element={<Home />} />
        <PrivateRoute path="calendar" element={<CalendarApp />} />
        <PrivateRoute path="dashboard" element={<Dashboard />} />
        <PrivateRoute path="faq" element={<Faq />} />
        <PrivateRoute path="help" element={<HelpCenter />} />
        <PrivateRoute path="profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileActivity />} />
          <PrivateRoute path="information" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
        </PrivateRoute>

        <PrivateRoute path="/redirect" element={<Redirect />} />
        <PrivateRoute path="vacancy">
          <PrivateRoute path="/" element={<Vacancy />} />
          <PrivateRoute
            path="/:id"
            element={
              <SelectionProvider>
                <VacancyPage />
              </SelectionProvider>
            }
          />
          <PrivateRoute path="add" element={<VacancyPage />} />
        </PrivateRoute>

        <PrivateRoute path="emails">
          <PrivateRoute path="/" element={<Emails />} />
          <PrivateRoute path="/:id" element={<EmailsPage />} />
          <PrivateRoute path="add" element={<VacancyPage />} />
        </PrivateRoute>

        <PrivateRoute path="settings">
          <PrivateRoute path="/" element={<Settings />} />
        </PrivateRoute>

        <PrivateRoute path="board">
          <PrivateRoute path="/" element={<Board />} />
        </PrivateRoute>

        <PrivateRoute path="res">
          <PrivateRoute path="/" element={<Res />} />
        </PrivateRoute>

        <PrivateRoute path="announce">
          <PrivateRoute path="/" element={<AnnounceTable />} />
          <PrivateRoute path="/:id" element={<Announce />} />
          <PrivateRoute path="add" element={<Announce />} />
        </PrivateRoute>

        <PrivateRoute path="candidate">
          <PrivateRoute path="/" element={<CandidateTable />} />
          <PrivateRoute path="/:id" element={<CandidatePage />} />
          <PrivateRoute path="add" element={<CandidatePage />} />
        </PrivateRoute>

        <PrivateRoute path="clients">
          <PrivateRoute path="/" element={<ClientsTable />} />
          <PrivateRoute path="/:id" element={<Clients />} />
          <PrivateRoute path="add" element={<Clients />} />
        </PrivateRoute>

        <PrivateRoute path="add" element={<VacancyPage />} />

        <PrivateRoute
          path="projects"
          element={<Navigate to={`/under-construction`} replace />}
        />
        <PrivateRoute path="user-management" element={<UserManagement />} />
      </PrivateRoute>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="forgot-password-submit" element={<ForgotPasswordSubmit />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="under-construction" element={<UnderConstructions />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route path="*" element={<Navigate to={`/404`} replace />} />
    </Routes>
  );
};

export default AppRoutesOld;
