import { Pessoal } from "../../admin/hooks/@types/candidateTypes";

export interface Event {
  id: string;
  title: string;
  description?: string;
  start: number | Date;
  end: number | Date;
  color?: EventColor;
  type?: string;
  event_type: number | string | undefined;
  event_situation: number | string | undefined;
  codchave: Pessoal | ClientTypes | string | number | undefined;
  codchave_OBJ?: Pessoal | undefined;
  contact_id: number | undefined;
}

export const eventColors = ["primary", "warning", "error", "success"] as const;

export const eventColorsJson = [
  { value: "1", label: "Candidato", color: "primary" },
  { value: "2", label: "Vagas", color: "warning" },
  { value: "3", label: "Clientes", color: "error" },
] as const;

export type EventColor = (typeof eventColors)[number];
